import { AccountInfo, AuthenticationResult } from '@azure/msal-common';
import { authenticationConfigService } from '../configsServices/authenticationConfigService';
import { errorHandlerService } from '../services/errorHandlerServices';
import { PublicClientApplication } from '@azure/msal-browser';

class AuthenticationService {
    private static readonly logoutChannelName = 'dcm-logout-channel';
    private static readonly logoutMessageType = 'dcm-logout';

    private get msalInstance(): PublicClientApplication {
        return authenticationConfigService.msalInstance;
    }

    async getAccessToken(): Promise<AuthenticationResult | null> {
        const account = this.getActiveAccount();
        return this.getAccessTokenByAccount(account);
    }

    async getAccessTokenByAccount(
        account: AccountInfo | null,
    ): Promise<AuthenticationResult | null> {
        if (!account) {
            return null;
        }

        const response: AuthenticationResult = await this.msalInstance.acquireTokenSilent({
            ...authenticationConfigService.loginRequest,
            account,
        });
        return response;
    }

    getActiveAccount(): AccountInfo | null {
        return this.msalInstance.getActiveAccount();
    }

    getDefaultAccount(): AccountInfo | null {
        const allAccounts = this.msalInstance.getAllAccounts();
        return allAccounts?.length ? allAccounts[0] : null;
    }

    async initiateTokenPopup(account: AccountInfo | null): Promise<AuthenticationResult | null> {
        if (!account) {
            return null;
        }

        return await this.msalInstance.acquireTokenPopup({
            ...authenticationConfigService.loginRequest,
            account,
        });
    }

    async login() {
        await this.msalInstance
            .loginRedirect(authenticationConfigService.loginRequest)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .catch((e: any) => {
                errorHandlerService.logError(e);
            });
    }

    async logout(account: AccountInfo | null = null, triggeredByUser: boolean = true) {
        if (triggeredByUser) {
            const messageChannel = this.createChannel();
            messageChannel.postMessage({ type: AuthenticationService.logoutMessageType });
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.msalInstance.logoutRedirect({ account }).catch((e: any) => {
            errorHandlerService.logError(e);
        });
    }

    subscribeLogoutEvents(): void {
        const messageChannel = this.createChannel();

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        messageChannel.onmessage = (message: MessageEvent<any>) => {
            if (message.data.type === AuthenticationService.logoutMessageType) {
                this.logout(null, false);
            }
        };
    }

    private createChannel(): BroadcastChannel {
        return new BroadcastChannel(AuthenticationService.logoutChannelName);
    }
}

export const authenticationService = new AuthenticationService();
