import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export function MonthPicker({
    value,
    onChange,
    minDate,
    maxDate,
}: {
    value: Dayjs | null;
    onChange: (date: Dayjs | undefined) => void;
    minDate: Dayjs;
    maxDate: Dayjs;
}) {
    const { t } = useTranslation();
    const [options, setOptions] = useState<Dayjs[]>([]);

    const getOptions = () => {
        const options = [];
        let currentDate = minDate.startOf('month').startOf('day');
        while (currentDate.isBefore(maxDate) || currentDate.isSame(maxDate, 'day')) {
            options.push(currentDate);
            currentDate = currentDate.add(1, 'month').startOf('month').startOf('day');
        }

        return options.reverse();
    };
    useEffect(() => {
        setOptions(getOptions);
    }, []);
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <FormControl fullWidth>
                <InputLabel id='month-picker'>{t('common.monthPicker')}</InputLabel>
                <Select
                    sx={{
                        height: 40,
                        width: 200,
                        '& .MuiSvgIcon-root': { transform: 'none!important' },
                    }}
                    MenuProps={{ sx: { maxHeight: '400px' } }}
                    labelId='label-month-picker'
                    id='month-picker'
                    value={value?.toISOString()}
                    label={t('common.monthPicker')}
                    IconComponent={CalendarMonthIcon}
                    onChange={(e) => e.target.value && onChange(dayjs(e.target.value))}
                >
                    {options.map((date, i) => (
                        <MenuItem key={i} value={date.toISOString()}>
                            {date.format('MMMM YYYY')}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </LocalizationProvider>
    );
}
