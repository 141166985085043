import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import {
    Box,
    SxProps,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
    useTheme,
} from '@mui/material';
import {
    ColumnDef,
    ExpandedState,
    Header,
    Row,
    RowData,
    SortingState,
    Table as TableType,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table';
import React, { useMemo, useState } from 'react';
import { TooltipWithVariousWidth } from '../styled/Tooltip';
import { ClientPagination } from './ClientPagination';

declare module '@tanstack/react-table' {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    interface TableMeta<TData extends RowData> {
        //ability to add handleFunc,someConst
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        objectsContainer?: Record<string, any>;
    }
}

export function ReactTableColumnResizer<T>({ header }: { header: Header<T, unknown> }) {
    const theme = useTheme();

    return (
        <Box
            onMouseDown={header.getResizeHandler()}
            onTouchStart={header.getResizeHandler()}
            className={`resizer ${header.column.getIsResizing() ? 'isResizing' : ''}`}
            sx={{
                position: 'absolute',
                right: 0,
                top: 0,
                height: '100%',
                width: '4px',
                cursor: 'col-resize',
                userSelect: 'none',
                touchAction: 'none',
                background: theme.palette.primary.light,

                '&.isResizing': {
                    background: theme.palette.primary.main,
                    opacity: 1,
                },
            }}
        />
    );
}

export function ReactTable<T>({
    columns,
    data,
    objectsContainer,
    globalFilter,
    enablePagination,
    enableSorting,
    defaultSorting,
    SubRow,
    cellSx,
    headerSx,
    isResizable,
    serverSideSortingState,
    stickyHeaders,
    hasDynamicColumns,
    columnsAlignment,
    colgroup,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getRowTooltip,
    maxTooltipWidth,
}: {
    columns: ColumnDef<T>[];
    data: T[];
    globalFilter?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    objectsContainer?: Record<string, any>;
    enablePagination?: boolean;
    enableSorting?: boolean;
    defaultSorting?: SortingState;
    SubRow?: React.FC<{ parentRow: Row<T>; parentTable: TableType<T> }>;
    cellSx?: SxProps<Theme>;
    headerSx?: SxProps<Theme>;
    isResizable?: boolean;
    serverSideSortingState?: SortingState;
    stickyHeaders?: boolean;
    hasDynamicColumns?: boolean;
    columnsAlignment?: {
        [k: string]: 'left' | 'center' | 'right';
    };
    colgroup?: React.ReactNode;
    getRowTooltip?: (rowData: T) => JSX.Element | null;
    maxTooltipWidth?: string;
}) {
    const theme = useTheme();

    const [expanded, setExpanded] = useState<ExpandedState>({});
    const [rowSelection, setRowSelection] = React.useState({});
    const [sorting, setSorting] = useState<SortingState>(defaultSorting ?? []);
    const defaultPagSize = 5;

    const table = useReactTable({
        data: data,
        columns: useMemo(() => columns, [hasDynamicColumns ? columns : null]),
        state: {
            expanded,
            rowSelection,
            sorting: serverSideSortingState ? serverSideSortingState : sorting,
            globalFilter,
        },
        columnResizeMode: 'onChange',
        getCoreRowModel: getCoreRowModel(),
        onExpandedChange: setExpanded,
        onRowSelectionChange: setRowSelection,
        getExpandedRowModel: getExpandedRowModel(),
        onSortingChange: serverSideSortingState ? () => {} : setSorting,
        getSortedRowModel: getSortedRowModel(),
        ...(enablePagination ? { getPaginationRowModel: getPaginationRowModel() } : {}),
        ...(enablePagination
            ? {
                  initialState: {
                      pagination: { pageSize: defaultPagSize, pageIndex: 0 },
                  },
              }
            : {}),
        getFilteredRowModel: getFilteredRowModel(),
        initialState: {
            pagination: { pageSize: defaultPagSize, pageIndex: 0 },
        },
        enableSorting: enableSorting ? true : false,
        meta: { objectsContainer },
    });

    return (
        <>
            <TableContainer sx={{ borderTop: `1px solid ${theme.palette.tableBorder}` }}>
                <Table size='small'>
                    {colgroup ? colgroup : null}
                    <TableHead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow
                                key={headerGroup.id}
                                sx={{
                                    boxShadow: `inset 0 -1px 0 ${theme.palette.tableBorder}`,
                                    ...(stickyHeaders
                                        ? {
                                              position: 'sticky',
                                              top: 0,
                                              zIndex: 100,
                                          }
                                        : {}),
                                }}
                            >
                                {headerGroup.headers.map((header, index, headers) => {
                                    const showResizer =
                                        isResizable &&
                                        index !== headers.length - 1 &&
                                        header.getContext().column.getCanResize();
                                    return (
                                        <TableCell
                                            key={header.id}
                                            colSpan={header.colSpan}
                                            sx={{
                                                backgroundColor: theme.palette.secondary.light,
                                                color: theme.palette.secondary.contrastText,
                                                fontSize: '14px',
                                                fontWeight: '600',
                                                position: 'relative',
                                                ...headerSx,
                                                width:
                                                    // 150 is defaultColumnSizing value
                                                    header.getSize() === 150
                                                        ? 'auto'
                                                        : header.getSize(),
                                            }}
                                            align={columnsAlignment?.[header.id] ?? 'left'}
                                        >
                                            {header.isPlaceholder ? null : (
                                                <Box
                                                    sx={{
                                                        '&:hover': {
                                                            cursor: header.column.getCanSort()
                                                                ? 'pointer'
                                                                : 'default',
                                                        },
                                                    }}
                                                    {...{
                                                        onClick:
                                                            header.column.getToggleSortingHandler(),
                                                    }}
                                                >
                                                    {flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext(),
                                                    )}
                                                    {{
                                                        asc: (
                                                            <NorthIcon
                                                                fontSize='inherit'
                                                                sx={{
                                                                    color: 'secondary.contrastText',
                                                                    position: 'absolute',
                                                                    zIndex: 100,
                                                                    mt: '4px',
                                                                }}
                                                            />
                                                        ),
                                                        desc: (
                                                            <SouthIcon
                                                                fontSize='inherit'
                                                                sx={{
                                                                    color: 'secondary.contrastText',
                                                                    position: 'absolute',
                                                                    zIndex: 100,
                                                                    mt: '4px',
                                                                }}
                                                            />
                                                        ),
                                                    }[header.column.getIsSorted() as string] ??
                                                        null}
                                                </Box>
                                            )}
                                            {showResizer && (
                                                <ReactTableColumnResizer<T> header={header} />
                                            )}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableHead>
                    <TableBody>
                        {table.getRowModel().rows.map((row) => {
                            return (
                                <React.Fragment key={row.id}>
                                    <TooltipWithVariousWidth
                                        id={row.id}
                                        title={getRowTooltip ? getRowTooltip(row.original) : ''}
                                        maxWidth={maxTooltipWidth}
                                        arrow
                                    >
                                        <TableRow
                                            sx={{
                                                '&:hover': {
                                                    backgroundColor: theme.palette.neutral3,
                                                },
                                                borderBottom: 'none',
                                            }}
                                        >
                                            {row.getVisibleCells().map((cell) => {
                                                return (
                                                    <TableCell
                                                        sx={{
                                                            color: theme.palette.secondary
                                                                .contrastText,
                                                            fontSize: '16px',
                                                            borderBottom: 'none',
                                                            ...cellSx,
                                                        }}
                                                        align={
                                                            columnsAlignment?.[cell.column.id] ??
                                                            'left'
                                                        }
                                                        key={cell.id}
                                                    >
                                                        {flexRender(
                                                            cell.column.columnDef.cell,
                                                            cell.getContext(),
                                                        )}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    </TooltipWithVariousWidth>
                                    {SubRow && row.getIsExpanded() && (
                                        <SubRow parentRow={row} parentTable={table} />
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {enablePagination && <ClientPagination table={table} options={[1, 2, 3, 4, 5]} />}
        </>
    );
}
