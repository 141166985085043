import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import { configurationService } from '../configsServices/appConfigService';

class AppInsightsService {
    private _instance!: ApplicationInsights;
    private _browserHistory = createBrowserHistory();
    private _reactPlugin = new ReactPlugin();

    get instance(): ApplicationInsights {
        if (!this._instance) {
            this._instance = new ApplicationInsights({
                config: {
                    instrumentationKey: configurationService.configuration.instrumentationKey,
                    enableAutoRouteTracking: true,
                    extensions: [this._reactPlugin],
                    extensionConfig: {
                        [this._reactPlugin.identifier]: {
                            history: this._browserHistory,
                        },
                    },
                },
            });

            this._instance.loadAppInsights();
        }

        return this._instance;
    }
}

export const appInsightsService = new AppInsightsService();
