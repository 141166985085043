import { CustomContentProps, useSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import '../snackbar.css';
import Alert from '@mui/material/Alert';

interface SnackbarProps extends CustomContentProps {
    allowDownload: boolean;
}

enum SnackbarType {
    Success,
    Error,
    Warning,
}

export const SuccessfulSnackbar = React.forwardRef<HTMLDivElement, SnackbarProps>(
    ({ id, ...props }, ref) => {
        return CustomSnackbar({ id, ...props }, ref, SnackbarType.Success);
    },
);

export const ErrorSnackbar = React.forwardRef<HTMLDivElement, SnackbarProps>(
    ({ id, ...props }, ref) => {
        return CustomSnackbar({ id, ...props }, ref, SnackbarType.Error);
    },
);

export const WarningSnackbar = React.forwardRef<HTMLDivElement, SnackbarProps>(
    ({ id, ...props }, ref) => {
        return CustomSnackbar({ id, ...props }, ref, SnackbarType.Warning);
    },
);

function CustomSnackbar(
    { id, ...props }: SnackbarProps,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ref: any,
    type: SnackbarType,
) {
    const { closeSnackbar } = useSnackbar();

    const handleDismiss = useCallback(() => {
        closeSnackbar(id);
    }, [id, closeSnackbar]);

    const action = (
        <React.Fragment>
            <IconButton
                size='small'
                aria-label='close'
                color='inherit'
                sx={{ p: 0.5 }}
                onClick={handleDismiss}
            >
                <CloseIcon
                    fontSize='small'
                    className={
                        type === SnackbarType.Error
                            ? 'errorCloseIcon'
                            : type === SnackbarType.Success
                            ? 'successCloseIcon'
                            : 'warningCloseIcon'
                    }
                />
            </IconButton>
        </React.Fragment>
    );

    return (
        <Alert
            ref={ref}
            severity={
                type === SnackbarType.Error
                    ? 'error'
                    : type === SnackbarType.Success
                    ? 'success'
                    : 'warning'
            }
            action={action}
            className={`baseSnackbar ${
                type === SnackbarType.Error
                    ? 'errorSnackbar'
                    : type === SnackbarType.Success
                    ? 'successSnackbar'
                    : 'warningSnackbar'
            }`}
        >
            {props.message}
        </Alert>
    );
}

SuccessfulSnackbar.displayName = 'SuccessfulSnackbar';
ErrorSnackbar.displayName = 'ErrorSnackbar';
WarningSnackbar.displayName = 'WarningSnackbar';
