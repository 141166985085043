import { Box } from '@mui/material';
import { DebouncedComponent } from '../debounced/DebouncedComponent';
import SlowLinearProgress from '../styled/SlowLinearProgress';
import { DefaultValueConstants } from '../../types/constants/DefaultValueConstants';

export function DebouncedLineLoader({
    loading,
    delayInMs = DefaultValueConstants.LoaderDelayInMs,
    sx,
}: {
    loading: boolean;
    delayInMs?: number;
    sx?: React.CSSProperties;
}) {
    return (
        <Box sx={sx}>
            <DebouncedComponent showChild={loading} delayInMs={delayInMs}>
                <SlowLinearProgress
                    sx={{ height: '2px', zIndex: 200, width: '100%', mb: '-2px' }}
                />
            </DebouncedComponent>
        </Box>
    );
}
