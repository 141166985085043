import i18n from 'i18next';
import languageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import enLocalization from '../localizations/en.json';
import noLocalization from '../localizations/no.json';

i18n.use(languageDetector)
    .use(initReactI18next)
    .init({
        debug: false,
        fallbackLng: 'no',
        resources: {
            en: {
                translation: enLocalization,
            },
            nb: {
                translation: noLocalization,
            },
            no: {
                translation: noLocalization,
            },
            nn: {
                translation: noLocalization,
            },
        },
    });
