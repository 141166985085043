import { Clear, Search } from '@mui/icons-material';
import { Box, InputAdornment, OutlinedInputProps, SxProps, Theme, useTheme } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OutlinedInput } from '../styled/OutlinedInput';

type SearchInputProps = {
    defaultValue?: string;
    handleSubmit: (inputValue: string) => void;
    sx?: SxProps<Theme> | undefined;
    inputSx?: React.CSSProperties;
    inputProps?: OutlinedInputProps;
};

export function SearchInput({
    defaultValue,
    handleSubmit,
    sx,
    inputSx,
    inputProps,
}: SearchInputProps) {
    const [intermediateInputValue, setIntermediateInputValue] = useState(defaultValue ?? '');
    const [inputValue, setInputValue] = useState(defaultValue ?? '');

    const { t } = useTranslation();
    const { palette } = useTheme();

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIntermediateInputValue(event.target.value);
    };

    const clearInput = () => {
        setIntermediateInputValue('');
        if (inputValue !== '') {
            handleSubmit('');
            setInputValue('');
        }
    };

    const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleSubmit(intermediateInputValue);
            setInputValue(intermediateInputValue);
        }
    };

    useEffect(() => {
        if (defaultValue !== undefined) {
            setIntermediateInputValue(defaultValue);
            setInputValue(defaultValue);
        }
    }, [defaultValue]);

    return (
        <Box sx={sx}>
            <OutlinedInput
                value={intermediateInputValue}
                onChange={handleInputChange}
                onKeyDown={handleOnKeyDown}
                size='small'
                sx={{
                    height: '36px',
                    width: 'inherit',
                    '.MuiOutlinedInput-input': { p: '7.5px 0 7.5px 15px' },
                    ...inputSx,
                }}
                placeholder={t('common.searchPlaceholder')}
                endAdornment={
                    <InputAdornment position='end'>
                        {intermediateInputValue || inputValue ? (
                            <Clear
                                onClick={clearInput}
                                sx={{ cursor: 'pointer', color: palette.secondary.dark }}
                                data-clear-icon
                            />
                        ) : (
                            <Search sx={{ color: palette.secondary.dark }} />
                        )}
                    </InputAdornment>
                }
                {...inputProps}
            />
        </Box>
    );
}
