import { TFunction } from 'i18next';
import { enqueueSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { SetterOrUpdater, useRecoilState, useSetRecoilState } from 'recoil';
import { UpdateStage } from '../enums/updateStage';
import { needUpdateDebtorsTable, refreshRiskIndicatorState } from '../store/recoilStore';
import { ActionResolver } from '../types/ActionResolver';

export function NotificationResolver() {
    const { t } = useTranslation();
    const [riskUpdateResolvers, setRiskUpdateResolvers] = useRecoilState(refreshRiskIndicatorState);
    const setDebtorTableUpdate = useSetRecoilState(needUpdateDebtorsTable);
    const { debtorId } = useParams();

    const handleRiskStageChange = (
        riskUpdateResolvers: Map<number, ActionResolver>,
        setRiskUpdateResolvers: SetterOrUpdater<Map<number, ActionResolver>>,
        t: TFunction<'translation', undefined>,
    ) => {
        const copy = new Map(riskUpdateResolvers);
        copy.forEach((entity, id) => {
            switch (entity.stage) {
                case UpdateStage.Success:
                    {
                        enqueueSnackbar(t('debtorsSection.successUpdateDebtorRiskData'), {
                            variant: 'successfulSnackbar',
                        });
                        riskUpdateResolvers.delete(id);
                        setRiskUpdateResolvers(riskUpdateResolvers);
                        +debtorId! === id! && entity.callBack?.();
                        setDebtorTableUpdate(true);
                    }
                    break;
                case UpdateStage.Error:
                    {
                        enqueueSnackbar(t('errorMessages.debtorRiskDataUpdate'), {
                            variant: 'errorSnackbar',
                        });
                        riskUpdateResolvers.delete(id);
                        setRiskUpdateResolvers(riskUpdateResolvers);
                    }
                    break;
            }
        });
    };

    useEffect(() => {
        handleRiskStageChange(riskUpdateResolvers, setRiskUpdateResolvers, t);
    }, [riskUpdateResolvers]);

    return <></>;
}
