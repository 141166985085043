import { FillSvgIconProps } from './SvgIconProps';
import theme from '../../theme/theme';

export function EmptyStateIcon({
    fill = theme.palette.primary.light,
    stroke = theme.palette.brandHovedfarge,
    ...props
}: FillSvgIconProps) {
    return (
        <svg
            width='193'
            height='158'
            viewBox='0 0 193 158'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <path
                d='M134.769 147.129C149.015 147.129 160.564 135.579 160.564 121.333L160.564 40.229C160.564 25.9829 149.015 14.4329 134.769 14.4329C133.129 14.4329 131.586 13.5874 130.7 12.2071C126.114 5.05856 118.097 0.321502 108.973 0.321502C94.726 0.321502 83.1768 11.8715 83.1768 26.1176C83.1768 27.305 81.8264 28.0208 80.7842 27.4518C77.1183 25.4504 72.9132 24.3127 68.4425 24.3127C54.1959 24.3127 42.6467 35.8626 42.6467 50.1088L42.6467 131.212C42.6467 145.459 54.1959 157.008 68.4425 157.008C82.6892 157.008 94.2384 145.459 94.2384 131.212L94.2384 117.44C94.2384 113.094 99.25 110.255 103.498 111.173C106.402 111.8 108.973 114.163 108.973 117.133L108.973 121.333C108.973 135.579 120.522 147.129 134.769 147.129Z'
                fill={fill}
            />
            <rect
                x='191.539'
                y='36.2016'
                width='98.3207'
                height='179.865'
                rx='4'
                transform='rotate(90 191.539 36.2016)'
                stroke={stroke}
                strokeWidth='2'
                strokeLinecap='round'
                strokeDasharray='8 8'
            />
            <path
                d='M10.7383 29.5433L9.77182 24.6364'
                stroke={stroke}
                strokeWidth='2'
                strokeLinecap='round'
            />
            <path
                d='M2.01172 36.2019L6.91853 35.2354'
                stroke={stroke}
                strokeWidth='2'
                strokeLinecap='round'
            />
            <path
                d='M1.83008 27.6938L5.98311 30.48'
                stroke={stroke}
                strokeWidth='2'
                strokeLinecap='round'
            />
            <line
                x1='66.3281'
                y1='65.2275'
                x2='136.883'
                y2='65.2275'
                stroke={stroke}
                strokeWidth='2'
                strokeLinecap='round'
            />
            <line
                x1='66.3281'
                y1='84.362'
                x2='136.883'
                y2='84.362'
                stroke={stroke}
                strokeWidth='2'
                strokeLinecap='round'
            />
            <line
                x1='66.3281'
                y1='103.496'
                x2='136.883'
                y2='103.496'
                stroke={stroke}
                strokeWidth='2'
                strokeLinecap='round'
            />
        </svg>
    );
}
