import { Tooltip, styled } from '@mui/material';
import { ReactNode } from 'react';
import theme from '../../theme/theme';

export const CustomTooltip = styled(Tooltip)``;

CustomTooltip.defaultProps = {
    placement: 'top',
    slotProps: {
        tooltip: {
            sx: {
                background: theme.palette.tooltipBackground,
                color: theme.palette.primary.contrastText,
                padding: '10px',
                fontSize: '14px',
                mb: '5px !important',
            },
        },
    },
    disableInteractive: true,
};

export function TooltipWithVariousWidth({
    id,
    title,
    maxWidth,
    children,
    arrow,
}: {
    id?:string;
    title: ReactNode;
    maxWidth?: string;
    children: JSX.Element;
    arrow?: boolean;
}) {
    if (!title) return children;
    return (
        <Tooltip
            id={`tooltip-with-various-width-${id}`}
            title={title}
            placement={'top'}
            arrow={arrow}
            slotProps={{
                tooltip: {
                    sx: {
                        background: theme.palette.tooltipBackground,
                        color: theme.palette.primary.contrastText,
                        padding: '10px',
                        fontSize: '14px',
                        mb: '5px !important',
                        maxWidth: maxWidth ? maxWidth : 'auto',
                    },
                },
            }}
        >
            {children}
        </Tooltip>
    );
}
