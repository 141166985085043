import MenuIcon from '@mui/icons-material/Menu';
import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContentModal } from '../../components/modals/ContentModal';
import theme from '../../theme/theme';
import { UserRoles } from '../../types/UserTypes';
import { RemindersStatisticTableSection } from './ReminderStatistic/RemindersStatisticTableSection';

export function SystemMenu({ userRole }: { userRole?: UserRoles | null }) {
    const { t } = useTranslation();
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [open, setOpen] = useState(false);
    const [pageComponent, setPageComponent] = useState<React.ReactNode | null>(null);
    const [pageName, setPageName] = useState('');

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleOpenNavMenu = (event: any) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleOpenModal = () => {
        handleCloseNavMenu();
        setOpen(true);
    };

    const handleChoosePage = (page: string) => {
        setPageName(page);
        switch (page) {
            case 'softReminder':
                setPageComponent(<RemindersStatisticTableSection />);
                handleOpenModal();
                break;
            default:
                break;
        }
    };
    const onModalClose = () => {
        setOpen(false);
        setPageComponent(null);
    };

    return (
        <>
            <Box>
                <ContentModal
                    title={pageName ? t(`creditorsStatistic.${pageName}.title`) : ''}
                    open={open}
                    onClose={onModalClose}
                    minWidth='35vw'
                >
                    {pageComponent}
                </ContentModal>
                <IconButton
                    size='large'
                    aria-controls='menu-appbar'
                    aria-haspopup='true'
                    onClick={handleOpenNavMenu}
                    color='inherit'
                    sx={{ pr: 0 }}
                >
                    <MenuIcon
                        style={{ fontSize: '32px', color: theme.palette.secondary.contrastText }}
                    />
                </IconButton>
                <Menu
                    id='menu-appbar'
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                >
                    {userRole === UserRoles.SettlAdmin ? (
                        <MenuItem onClick={() => handleChoosePage('softReminder')}>
                            <Typography
                                textAlign='center'
                                color={theme.palette.secondary.contrastText}
                            >
                                {t(`creditorsStatistic.softReminder.title`)}
                            </Typography>
                        </MenuItem>
                    ) : null}
                </Menu>
            </Box>
        </>
    );
}
