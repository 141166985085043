interface Config {
    apiPath: string;
    azureClientId: string;
    azureRedirectUri: string;
    azureTenantId: string;
    loginScope: string;
    instrumentationKey: string;
    logToConsole: boolean;
    settlCollectionCaseLink: string;
    hotjarSiteId: string;
    customerSupportEmail: string;
    minimumCollectionCasesCountColoring: number;
}

class ConfigurationService {
    private _configuration: Config = {} as Config;

    get configuration(): Config {
        return this._configuration;
    }

    async load(): Promise<Config | null> {
        try {
            const fileName = `${process.env.PUBLIC_URL}/assets/configs/config.${process.env.REACT_APP_ENV}.json`;
            const response = await fetch(fileName);
            if (!response.ok) {
                throw new Error('Failed to fetch config file');
            }

            this._configuration = await response.json();
            return this._configuration;
        } catch (error) {
            console.error('Error loading config:', error);
            return null;
        }
    }
}

export const configurationService = new ConfigurationService();
