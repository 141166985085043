import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { CreditorSoftReminderStatisticDto } from '../../../components/hooks/useRemindersStatisticService';
import { formatDecimal, formatNumber } from '../../../utils/formatter';

export const getReminderStatisticTableColumns = (
    t: ReturnType<typeof useTranslation>['t'],
): ColumnDef<CreditorSoftReminderStatisticDto>[] => [
    {
        header: t('adminSection.creditorTreeSelectionContext.creditorsTable.companyNameLabel'),
        accessorKey: 'Name',
        cell: ({ cell }) => <>{cell.getValue()}</>,
    },
    {
        header: t('adminSection.creditorTreeSelectionContext.creditorsTable.creditorNumberLabel'),
        accessorKey: 'Number',
        cell: ({ cell }) => <>{cell.getValue()}</>,
    },
    {
        header: t('creditorsStatistic.softReminder.tableHeaders.price'),
        accessorKey: 'Price',
        cell: ({ cell }) => <>{formatDecimal(cell.getValue())}</>,
    },
    {
        header: t('creditorsStatistic.softReminder.tableHeaders.reminderCount'),
        accessorKey: 'Count',
        cell: ({ cell }) => <>{formatNumber(cell.getValue())}</>,
    },
    {
        header: t('creditorsStatistic.softReminder.tableHeaders.total'),
        accessorKey: 'TotalAmount',
        cell: ({ row }) => <>{formatDecimal(row.original.TotalAmount)}</>,
    },
];
