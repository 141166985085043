import {
    EditUserDto,
    RecipientUser,
    RecipientUserDto,
    User,
    UserDto,
    UserRoles,
    UserRolesFilters,
} from '../types/UserTypes';

export const mapRecipientUserDtos = (userData: RecipientUserDto[]): RecipientUser[] => {
    return userData.map(mapRecipientUserDto);
};

export const mapUserDto = (userDto: UserDto): User => {
    return {
        id: userDto.Id,
        firstName: userDto.FirstName,
        lastName: userDto.LastName,
        role: userDto.Role as UserRoles,
        email: userDto.Email,
        registrationPassed: userDto.RegistrationPassed,
    };
};

export const mapRecipientUserDto = (userDto: RecipientUserDto): RecipientUser => {
    return {
        ...mapUserDto(userDto),
        IsRecipient: userDto.IsRecipient,
    };
};

export const mapUser = (user: User, creditorGroupIds: number[]): UserDto => {
    return {
        Id: user.id!,
        FirstName: user.firstName,
        LastName: user.lastName,
        Role: +user.role!,
        Email: user.email,
        RegistrationPassed: user.registrationPassed,
        CreditorGroupIds: creditorGroupIds,
    };
};

export const mapToEditUserDto = (user: User): EditUserDto => {
    return {
        Id: user.id!,
        FirstName: user.firstName,
        LastName: user.lastName,
        Role: +user.role!,
    };
};

export const registrationPassedDisplayNames: Record<string, string> = {
    true: 'statusActive',
    false: 'statusInvited',
};

export const convertUserRoleFilter = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    query: Record<string, any> & { userRoleFilter: UserRolesFilters },
) => ({
    ...query,
    ...(query.userRoleFilter === UserRolesFilters.All ? { userRoleFilter: null } : {}),
});
