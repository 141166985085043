import { appInsightsService } from './appInsightsService';
import { configurationService } from '../configsServices/appConfigService';

class ErrorHandlerService {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    logError(ex: any) {
        appInsightsService.instance.trackException(ex);

        if (configurationService.configuration.logToConsole) {
            console.error(ex);
        }
    }
}

export const errorHandlerService = new ErrorHandlerService();
