import { Box, Stack } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { EmptyState, EmptyStateType } from '../../../components/EmptyState';
import { MonthPicker } from '../../../components/datePickers/MonthPicker';
import { DebouncedComponent } from '../../../components/debounced/DebouncedComponent';
import {
    CreditorSoftReminderStatisticDto,
    useRemindersStatisticService,
} from '../../../components/hooks/useRemindersStatisticService';
import { SearchInput } from '../../../components/inputs/SearchInput';
import { DebouncedLineLoader } from '../../../components/loaders/DebouncedLineLoader';
import { ReactTable } from '../../../components/tables/ReactTable';
import { accessibleCreditorsState } from '../../../store/recoilStore';
import { getReminderStatisticTableColumns } from './ReminderStatisticTableColumns';

export function RemindersStatisticTableSection() {
    const { data, setQueryOptions, queryOptions, loading } = useRemindersStatisticService();
    const { t } = useTranslation();
    const accessibleCreditors = useRecoilValue(accessibleCreditorsState);
    const [search, setSearch] = useState('');

    const columnsAlignment: Partial<{
        [k in keyof CreditorSoftReminderStatisticDto]: 'left' | 'center' | 'right';
    }> = {
        Name: 'left',
        Number: 'left',
        Price: 'right',
        Count: 'right',
        TotalAmount: 'right',
    };

    const onDateChange = (date: Dayjs | undefined) => {
        date &&
            setQueryOptions((prev) => ({
                ...prev,
                TargetDate: date,
                PageNumber: 1,
            }));
    };

    return (
        <>
            {!accessibleCreditors || accessibleCreditors?.length === 0 ? (
                <DebouncedComponent showChild={true}>
                    <EmptyState message={t('common.noAccessibleCreditors')} />
                </DebouncedComponent>
            ) : (
                <>
                    <Stack
                        mb={2}
                        direction={{ xs: 'column', md: 'row' }}
                        gap={2}
                        justifyContent={'space-between'}
                        alignItems={'flex-end'}
                    >
                        <Stack direction='column' alignItems={'flex-end'}>
                            <Stack
                                direction={{ xs: 'column', md: 'row' }}
                                justifyContent={'end'}
                                gap={2}
                            >
                                <MonthPicker
                                    value={queryOptions.TargetDate}
                                    minDate={dayjs()
                                        .add(-1, 'year')
                                        .startOf('month')
                                        .startOf('day')}
                                    maxDate={dayjs().startOf('month').startOf('day')}
                                    onChange={onDateChange}
                                />
                                <SearchInput
                                    defaultValue={search}
                                    handleSubmit={(input) => {
                                        setSearch(input);
                                    }}
                                    sx={{
                                        width: '300px',
                                        '& .MuiOutlinedInput-root': { height: '40px!important' },
                                    }}
                                />
                            </Stack>
                        </Stack>
                    </Stack>
                    {data.length ? (
                        <>
                            <DebouncedLineLoader loading={loading} />
                            <Box
                                sx={{
                                    display: 'flex',
                                    height: 'calc(90vh - 155px)',
                                }}
                            >
                                <ReactTable<CreditorSoftReminderStatisticDto>
                                    data={data}
                                    columns={getReminderStatisticTableColumns(t)}
                                    colgroup={
                                        <colgroup>
                                            <col width='30%' />
                                            <col width='20%' />
                                            <col width='10%' />
                                            <col width='20%' />
                                            <col width='20%' />
                                        </colgroup>
                                    }
                                    cellSx={{ padding: '8px 16px' }}
                                    headerSx={{ padding: '8px 16px', whiteSpace: 'nowrap' }}
                                    stickyHeaders
                                    columnsAlignment={columnsAlignment}
                                    hasDynamicColumns={true}
                                    globalFilter={search}
                                    enableSorting
                                />
                            </Box>
                        </>
                    ) : loading ? (
                        <></>
                    ) : (
                        <Stack pt={'80px'} width={'100%'} gap={'29px'} alignItems={'center'}>
                            {loading ? (
                                <></>
                            ) : (
                                <DebouncedComponent showChild={true}>
                                    <EmptyState
                                        type={EmptyStateType.Search}
                                        message={t('common.noSearchResultsMessage')}
                                    />
                                </DebouncedComponent>
                            )}
                        </Stack>
                    )}
                </>
            )}
        </>
    );
}
