import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';

export function ContentModal({
    title,
    open,
    onClose,
    children,
    minWidth,
}: {
    title: string;
    open: boolean;
    onClose: () => void;
    minWidth?: string;
    children: React.ReactNode;
}) {
    return (
        <Dialog
            sx={{
                '& .MuiPaper-root': {
                    maxWidth: '70vw!important',
                    minWidth: minWidth ? `${minWidth}!important` : '30vw!important',
                    minHeight: '90vh!important',
                },
            }}
            onClose={onClose}
            aria-labelledby='customized-dialog-title'
            open={open}
        >
            <DialogTitle sx={{ m: 0, p: 2, fontWeight: 600 }} id='customized-dialog-title'>
                {title}
            </DialogTitle>
            <IconButton
                aria-label='close'
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>{children}</DialogContent>
        </Dialog>
    );
}
