import {
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Pagination,
    Stack,
    Typography,
} from '@mui/material';
import grey from '@mui/material/colors/grey';
import { Table } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { useDebounce } from '../../utils/debounce';
import { Select } from '../styled/Select';
import { OutlinedInput } from '../styled/OutlinedInput';

export function ClientPagination({
    table,
    options,
}: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    table: Table<any>;
    options: number[];
}) {
    const { t } = useTranslation();

    const debouncedSetPage = useDebounce(table.setPageIndex, 1000);
    const onGoToChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = +e.target.value;
        if (value > 0 && value <= table.getPageCount()) {
            debouncedSetPage(value - 1);
        } else e.target.value = e.target.value.slice(0, e.target.value.length - 1);
    };

    return (
        <Box sx={{ flexShrink: 0, marginTop: 2 }}>
            <Stack direction={'row'} justifyContent={'right'}>
                <Typography fontSize={'16px'} variant='body2' color={grey[800]} mx={1} mt={0.5}>
                    {t('common.paginationTotalItemsText', {
                        count: table.options.data.length,
                    })}
                </Typography>
                <Pagination
                    page={table.getState().pagination.pageIndex + 1}
                    count={table.getPageCount()}
                    onChange={(e, page) => table.setPageIndex(page - 1)}
                    hideNextButton={!table.getCanNextPage()}
                    hidePrevButton={!table.getCanPreviousPage()}
                    shape='rounded'
                    showFirstButton
                    showLastButton
                    sx={{ '& button': { color: 'secondary.contrastText' } }}
                />
                <Box>
                    <FormControl fullWidth>
                        <InputLabel id='label-per-page'>
                            {t('common.paginationPerPageText')}
                        </InputLabel>
                        <Select
                            sx={{ height: 30, width: 90 }}
                            labelId='label-per-page'
                            id='per-page'
                            value={table.getState().pagination.pageSize}
                            label='per page'
                            onChange={(e) =>
                                table.setPageSize(+(e.target.value as string | number))
                            }
                        >
                            {options.map((size) => (
                                <MenuItem key={size} value={size}>
                                    {size}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <Typography fontSize={'16px'} variant='body2' color={grey[800]} mx={1} mt={0.5}>
                    {t('common.paginationGoToText')}
                </Typography>
                <OutlinedInput
                    sx={{ height: 30, width: 80 }}
                    onBlur={(e) => {
                        e.currentTarget.value = '';
                    }}
                    type='number'
                    onChange={onGoToChange}
                    size='small'
                    inputProps={{ min: 1, max: table.getPageCount() }}
                    placeholder=''
                />
            </Stack>
        </Box>
    );
}
