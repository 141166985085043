import { Box, Button, Stack, Typography } from '@mui/material';
import grey from '@mui/material/colors/grey';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { FullscreenBox } from '../components/FullscreenBox';
import { useInvitationService } from '../components/hooks/useInvitationService';
import { Logo } from '../components/Icons/Logo';
import theme from '../theme/theme';
import { configurationService } from '../configsServices/appConfigService';

const footerInfo = {
    phoneNumber: '22 12 01 21 (8-16)',
    email: configurationService.configuration.customerSupportEmail,
    address: 'kongens gate 12, 0153 oslo',
};

export function HomePage({ handleLogin }: { handleLogin: () => void }) {
    const { tryCompleteRegistration } = useInvitationService();
    const [searchParams] = useSearchParams();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const processToken = async () => {
        const token = searchParams.get('token');

        if (token) {
            if (await tryCompleteRegistration(token)) {
                enqueueSnackbar(t('common.successCompleteRegistration'), {
                    variant: 'successfulSnackbar',
                });
            }
        }
    };

    const handleContinueButtonClick = async () => {
        await processToken();
        handleLogin();
    };

    return (
        <FullscreenBox
            backgroundImage='/background.jpg'
            sx={{
                position: 'relative',
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -85%)',
                }}
            >
                <Logo
                    width='244px'
                    height='82px'
                    fill={theme.palette.secondary.main}
                    display={'flex'}
                />
                <Button
                    sx={{
                        mt: '40px',
                        bgcolor: 'white',
                        color: 'black',
                        width: '100%',
                        ':hover': { bgcolor: grey[400] },
                    }}
                    onClick={handleContinueButtonClick}
                >
                    {t('common.continueButtonText')}
                </Button>
            </Box>
            <Stack
                direction={'row'}
                sx={{
                    position: 'absolute',
                    height: '70px',
                    bottom: 0,
                    width: '100%',
                    backgroundColor: '#F5F5F5B2',
                }}
                spacing={8}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Typography>{footerInfo.phoneNumber}</Typography>
                <Typography>{footerInfo.email}</Typography>
                <Typography>{footerInfo.address}</Typography>
            </Stack>
        </FullscreenBox>
    );
}
