import axios from 'axios';
import { StatusCodes } from 'http-status-codes';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { authenticationService } from '../../auth/authenticationService';
import { configurationService } from '../../configsServices/appConfigService';
import { authenticatedUser, globalLoadingState } from '../../store/recoilStore';

let activeRequestsCount = 0;

export const useApi = (disableGlobalLoadingIndicator?: boolean) => {
    const navigate = useNavigate();
    const setGlobalLoadingState = useSetRecoilState(globalLoadingState);
    const [currentUser, setCurrentUser] = useRecoilState(authenticatedUser);
    const startLoading = () => {
        if (!disableGlobalLoadingIndicator) {
            activeRequestsCount++;
            setGlobalLoadingState(true);
        }
    };
    const stopLoading = () => {
        if (!disableGlobalLoadingIndicator) {
            activeRequestsCount--;
            setGlobalLoadingState(!!activeRequestsCount);
        }
    };

    const apiInstance = useMemo(() => {
        const instance = axios.create({
            baseURL: configurationService.configuration.apiPath,
        });

        instance.interceptors.request.use(
            async (config) => {
                startLoading();
                const accessToken = (await authenticationService.getAccessToken())?.accessToken;
                if (accessToken) {
                    config.headers['Authorization'] = `Bearer ${accessToken}`;
                }
                return config;
            },
            (error) => {
                stopLoading();
                return Promise.reject(error);
            },
        );

        instance.interceptors.response.use(
            (response) => {
                stopLoading();
                return response;
            },
            (error) => {
                stopLoading();
                if (error.response?.status === StatusCodes.UNAUTHORIZED) {
                    navigate('/unauthorized');
                }
                if (error.response?.status === StatusCodes.FORBIDDEN) {
                    //will trigger creditors list update in CreditorSelectorControl
                    currentUser && setCurrentUser({ ...currentUser });
                }
                return Promise.reject(error);
            },
        );

        return instance;
    }, []);

    return apiInstance;
};

export const usePublicApi = () =>
    axios.create({
        baseURL: configurationService.configuration.apiPath,
    });

export const headerValues = { appJson: { 'Content-Type': 'application/json' } };
