import { configurationService } from './appConfigService';
import Hotjar from '@hotjar/browser';

class FeedbackService {
    initialize(): void {
        const siteId: number = +configurationService.configuration.hotjarSiteId;
        if (!siteId) {
            return;
        }

        const hotjarVersion = 6;
        Hotjar.init(siteId, hotjarVersion);
    }
}

export const feedbackService = new FeedbackService();
