import { StatusCodes } from 'http-status-codes';
import { useApi } from './useApi';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

export function useInvitationService() {
    const api = useApi();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    async function tryReinvite(userId: number) {
        try {
            const response = await api.put(`users/invitations/${userId}`);

            if (response.status !== StatusCodes.NO_CONTENT) throw new Error();
            else return true;
        } catch (_) {
            enqueueSnackbar(t('errorMessages.userReinvite'), {
                variant: 'errorSnackbar',
            });
        }
    }

    async function tryCompleteRegistration(token: string) {
        try {
            const response = await api.put(`users/invitations/completion/${token}`);

            if (response.status !== StatusCodes.NO_CONTENT) throw new Error();
            else return true;
        } catch (_) {
            enqueueSnackbar(t('errorMessages.userCompleteRegistration'), {
                variant: 'errorSnackbar',
            });
        }
    }

    return { tryReinvite, tryCompleteRegistration };
}
