import { MsalProvider } from '@azure/msal-react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import App from './App';
import { authenticationConfigService } from './configsServices/authenticationConfigService';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { configurationService } from './configsServices/appConfigService';
import './configsServices/localizationService';
import { feedbackService } from './configsServices/feedbackService';
import { authenticationService } from './auth/authenticationService';

configurationService.load().then(() => {
    const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

    authenticationConfigService.initialize();
    authenticationService.subscribeLogoutEvents();
    feedbackService.initialize();

    root.render(
        <React.StrictMode>
            <RecoilRoot>
                <MsalProvider instance={authenticationConfigService.msalInstance}>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </MsalProvider>
            </RecoilRoot>
        </React.StrictMode>,
    );

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
});
