export enum UserRoles {
    CreditorUser = 1,
    CreditorAdmin = 2,
    SettlUser = 3,
    SettlAdmin = 4,
}

export enum UserRolesFilters {
    All = 0,
    CreditorUser = 1,
    CreditorAdmin = 2,
    SettlUser = 3,
    SettlAdmin = 4,
}

export type RecipientUser = User & {
    IsRecipient: boolean;
};

export type User = {
    id: number | null;
    firstName: string;
    lastName: string;
    email: string;
    registrationPassed: boolean;
    role: UserRoles | null;
};

export type RecipientUserDto = UserDto & {
    IsRecipient: boolean;
};

export type UserDto = {
    Id: number;
    FirstName: string;
    LastName: string;
    Role: number;
    Email: string;
    RegistrationPassed: boolean;
    CreditorGroupIds: number[];
};

export type EditUserDto = {
    Id: number;
    FirstName: string;
    LastName: string;
    Role: number;
};

export enum UserAssignType {
    Existing,
    New,
}

export type GetUsersQuery = {
    searchInput: string;
    userRoleFilter: UserRolesFilters;
    canCreditorUserSeeCreditorAdmin: boolean;
};
