import { SvgIconProps } from '@mui/material';

export function EnglishFlagIcon({ props }: { props?: SvgIconProps }) {
    return (
        <svg
            width='24'
            height='24'
            viewBox='0 0 50 51'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <g clipPath='url(#clip0_5507_10565)'>
                <path
                    d='M49.1382 41.4097H0.862594C0.386522 41.4097 0.000488281 41.0237 0.000488281 40.5476V9.51323C0.000488281 9.03716 0.386522 8.65112 0.862594 8.65112H49.1383C49.6144 8.65112 50.0004 9.03716 50.0004 9.51323V40.5476C50.0003 41.0237 49.6143 41.4097 49.1382 41.4097Z'
                    fill='#41479B'
                />
                <path
                    d='M50.0003 9.51323C50.0003 9.03716 49.6143 8.65112 49.1382 8.65112H45.2813L29.3106 19.1147V8.65112H20.69V19.1147L4.71941 8.65112H0.862594C0.386522 8.65112 0.000488281 9.03716 0.000488281 9.51323V11.7428L13.7025 20.72H0.000488281V29.3407H13.7025L0.000488281 38.318V40.5476C0.000488281 41.0237 0.386522 41.4097 0.862594 41.4097H4.71951L20.6902 30.9462V41.4097H29.3108V30.9462L45.2815 41.4097H49.1384C49.6145 41.4097 50.0005 41.0237 50.0005 40.5476V38.318L36.2985 29.3408H50.0003V20.7201H36.2983L50.0003 11.7428V9.51323Z'
                    fill='#F5F5F5'
                />
                <path
                    d='M50.0003 22.4442H27.5866V8.65112H22.4142V22.4442H0.000488281V27.6166H22.4142V41.4097H27.5866V27.6166H50.0003V22.4442Z'
                    fill='#FF4B55'
                />
                <path
                    d='M17.4763 29.3408L0.0253906 40.6686C0.0864255 41.0849 0.429686 41.4098 0.862985 41.4098H2.05038L20.6431 29.3409H17.4763V29.3408Z'
                    fill='#FF4B55'
                />
                <path
                    d='M33.8279 29.3408H30.6611L49.2268 41.3919C49.6595 41.3458 50.001 40.9925 50.001 40.5476V39.8388L33.8279 29.3408Z'
                    fill='#FF4B55'
                />
                <path
                    d='M0.000976562 10.465L15.7996 20.7202H18.9664L0.503221 8.73529C0.2083 8.8723 0.000976562 9.16673 0.000976562 9.51331V10.465Z'
                    fill='#FF4B55'
                />
                <path
                    d='M32.4783 20.7203L49.9711 9.36513C49.8985 8.9626 49.5625 8.65137 49.139 8.65137H47.9039L29.3115 20.7203H32.4783Z'
                    fill='#FF4B55'
                />
            </g>
            <defs>
                <clipPath id='clip0_5507_10565'>
                    <rect width='50' height='50' fill='white' transform='translate(0 0.0305176)' />
                </clipPath>
            </defs>
        </svg>
    );
}
