import { AxiosResponse } from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import { StatusCodes } from 'http-status-codes';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from './useApi';

export type CreditorSoftReminderStatisticDto = {
    Name: string;
    Number: string;
    Price: number;
    Count: number;
    TotalAmount: number;
};

export type SoftReminderStaticQueryOptions = {
    TargetDate: Dayjs;
};

const defaultQueryOptions: SoftReminderStaticQueryOptions = {
    TargetDate: dayjs().startOf('month').startOf('day'),
};

export function useRemindersStatisticService() {
    const [data, setData] = useState<CreditorSoftReminderStatisticDto[]>([]);
    const [loading, setLoading] = useState(false);
    const [queryOptions, setQueryOptions] =
        useState<SoftReminderStaticQueryOptions>(defaultQueryOptions);
    const api = useApi();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const lastPromise = useRef<Promise<AxiosResponse<CreditorSoftReminderStatisticDto[]>>>();

    async function tryRefetch(queryOptions: SoftReminderStaticQueryOptions) {
        setLoading(true);
        try {
            const currentPromise = api.get<CreditorSoftReminderStatisticDto[]>(
                `statistics/soft-reminder-statistics?date=${queryOptions.TargetDate.format(
                    'YYYY-MM-DD',
                )}`,
            );
            lastPromise.current = currentPromise;
            const response = await currentPromise;

            if (response.status !== StatusCodes.OK) {
                throw new Error();
            } else {
                if (lastPromise.current == currentPromise) {
                    setData(response.data);
                }
            }
        } catch (_) {
            enqueueSnackbar(t('errorMessages.softReminderStatisticFetch'), {
                variant: 'errorSnackbar',
            });
        }
        setLoading(false);
    }

    useEffect(() => {
        tryRefetch(queryOptions);
    }, [queryOptions]);

    return { data, setQueryOptions, queryOptions, loading };
}
