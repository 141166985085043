import { Outlet } from 'react-router-dom';
import { DebouncedGlobalLoader } from './loaders/DebouncedGlobalLoader';
import { NavigationBar } from './NavigationBar';
import { NotificationResolver } from './NotificationResolver';

export function Layout() {
    return (
        <div className='app-container'>
            <DebouncedGlobalLoader />
            <NotificationResolver />
            <NavigationBar />
            <Outlet />
        </div>
    );
}
