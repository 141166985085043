import { useApi } from './useApi';
import { mapUserDto } from '../../mapping/UserMapping';
import { useRecoilState } from 'recoil';
import { authenticatedUser } from '../../store/recoilStore';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

export function useCurrentUserService() {
    const [currentUser, setCurrentUser] = useRecoilState(authenticatedUser);

    const api = useApi();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    async function tryRefetchCurrentUser() {
        try {
            const response = await api.get('auth/user/');
            const user = mapUserDto(response.data);

            if (!user) throw new Error();
            else {
                setCurrentUser(user);
                return true;
            }
        } catch (_) {
            enqueueSnackbar(t('errorMessages.usersFetch'), {
                variant: 'errorSnackbar',
            });
        }
    }

    return { currentUser, tryRefetchCurrentUser };
}
