import theme from '../../theme/theme';
import { FillSvgIconProps } from './SvgIconProps';

export function Logo({ fill = theme.palette.logoFill, ...props }: FillSvgIconProps) {
    return (
        <svg
            width='96'
            height='32'
            viewBox='0 0 96 32'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            display={'flex'}
            {...props}
        >
            <rect width='96' height='32' fill='none' />
            <g id='Settl logo' clipPath='#clip0_0_1'>
                <path
                    id='Vector'
                    d='M9.88618 7.58813C14.439 7.58813 18.6016 9.79951 19.5556 14.4825H14.6992C14.1355 12.3578 12.1843 11.534 9.58266 11.534C7.32791 11.534 5.24661 12.5313 5.24661 14.0922C5.24661 15.6532 6.33062 16.4771 8.36856 16.8673L11.664 17.5177C16.3902 18.4283 19.9892 19.9025 19.9892 24.4554C19.9892 29.3118 15.393 32.0001 10.103 32.0001C4.3794 32.0001 0.563686 28.7047 0 24.3253H5.02981C5.50678 26.7535 7.19783 28.0977 10.2764 28.0977C13.0081 28.0977 14.9594 26.9703 14.9594 25.0624C14.9594 23.1546 13.2249 22.3307 10.9268 21.8538L7.41463 21.16C3.46883 20.3361 0.390244 18.6451 0.390244 14.3524C0.390244 10.2332 4.85637 7.58813 9.88618 7.58813Z'
                    fill={fill}
                />
                <path
                    id='Vector_2'
                    d='M47.0127 8.10835H50.4815V1.95117H55.6414V8.10835H65.2173V1.95117H70.3772V8.10835H75.0601V11.8374H70.3772V24.542C70.3772 26.9268 70.9409 27.4905 73.1522 27.4905H75.0167V31.4363H71.201C66.2146 31.4363 65.2173 30.0921 65.2173 25.3225V11.8374H55.6414V24.542C55.6414 26.9268 56.2051 27.4905 58.4165 27.4905H60.281V31.4363H56.4653C51.4788 31.4363 50.4815 30.0921 50.4815 25.3225V11.8374H47.0127V8.10835Z'
                    fill={fill}
                />
                <path id='Vector_3' d='M79.2163 0H84.3762V31.4363H79.2163V0Z' fill={fill} />
                <path
                    id='Vector_4'
                    d='M95.4297 25.5393V31.4363H89.5327V25.5393H95.4297Z'
                    fill={fill}
                />
                <path
                    id='Vector_5'
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M45.4959 21.2467C45.9729 13.4418 41.4634 7.58813 34.3089 7.58813C27.6748 7.58813 22.8618 12.5746 22.8618 19.599C22.8618 26.3199 27.1545 32.0001 34.6558 32.0001C40.6396 32.0001 44.1951 28.1844 45.2358 24.4554H40.1626C39.252 26.6668 37.1707 27.7074 34.439 27.7074C31.4905 27.7074 28.4553 25.5828 28.1084 21.2467H45.4959ZM40.2493 17.4744C40.1192 13.8321 37.3442 11.6207 34.3089 11.6207C31.8808 11.6207 28.5854 13.095 28.1084 17.4744H40.2493Z'
                    fill={fill}
                />
            </g>
            <defs>
                <clipPath id='clip0_0_1'>
                    <rect width='96' height='32' fill='none' />
                </clipPath>
            </defs>
        </svg>
    );
}
