import { Box, Typography } from '@mui/material';
import theme from '../theme/theme';
import { EmptyStateIcon } from './Icons/EmptyStateIcon';
import { EmptyStateSearchIcon } from './Icons/EmptyStateSearchIcon';

export enum EmptyStateType {
    Default = 1,
    Search = 2,
}

export function EmptyState({
    header,
    message,
    type = EmptyStateType.Default,
    sx,
}: {
    header?: string;
    message: React.ReactNode | string;
    type?: EmptyStateType;
    sx?: React.CSSProperties;
}) {
    let emptyStateIcon: React.ReactNode;

    switch (type) {
        case EmptyStateType.Default:
            emptyStateIcon = <EmptyStateIcon />;
            break;
        case EmptyStateType.Search:
            emptyStateIcon = <EmptyStateSearchIcon />;
            break;
        default:
            throw new Error('Wrong empty state type value');
    }

    const headerComponent = (
        <Typography
            sx={{
                fontSize: '24px',
                color: theme.palette.secondary.dark,
                mt: 4,
            }}
        >
            {header}
        </Typography>
    );

    const messageComponent = (
        <Typography
            sx={{
                fontSize: '18px',
                color: theme.palette.secondary.dark,
                mt: header ? 1 : 4,
                width: '80%',
            }}
        >
            {message}
        </Typography>
    );

    return (
        <Box
            sx={{
                width: '100%',
                height: '90%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                textAlign: 'center',
                ...sx,
            }}
        >
            {emptyStateIcon}
            {header ? (
                <>
                    {headerComponent}
                    {messageComponent}
                </>
            ) : (
                messageComponent
            )}
        </Box>
    );
}
