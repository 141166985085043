import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { authenticatedUser } from '../store/recoilStore';
import { UserRoles } from '../types/UserTypes';
import { useCurrentUserService } from './hooks/useCurrentUserService';
import { ConfirmationModal } from './modals/ConfirmationModal';
import { CustomTooltip } from './styled/Tooltip';
import { useTheme } from '@mui/material';
import { CustomMenu } from './styled/Menu';
import { Button } from './styled/Button';
import { authenticationService } from '../auth/authenticationService';

export function AccountInfo() {
    const user = useRecoilValue(authenticatedUser);
    const { tryRefetchCurrentUser } = useCurrentUserService();

    const [modalOpen, setModalOpen] = useState(false);
    const [anchorElUser, setAnchorElUser] = useState(null);

    const { t } = useTranslation();
    const { palette } = useTheme();

    if (!user) {
        return <></>;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleOpenUserMenu = (event: any) => {
        tryRefetchCurrentUser();
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleLogout = () => {
        authenticationService.logout();
    };

    const onModalClose = () => {
        setModalOpen(false);
        setAnchorElUser(null);
    };

    return (
        <Box sx={{ flexGrow: 0 }}>
            <ConfirmationModal
                messages={[t('common.signOutConfirmationText')]}
                modalOpen={modalOpen}
                onClose={onModalClose}
                handleSubmit={handleLogout}
            />
            <CustomTooltip title={t('common.accountDetailsTooltip')}>
                <IconButton sx={{ pr: 0 }} size='large' onClick={handleOpenUserMenu} disableRipple>
                    <PersonIcon htmlColor={palette.secondary.contrastText} />
                </IconButton>
            </CustomTooltip>
            <CustomMenu
                sx={{
                    mt: '38px',
                    '& .MuiPaper-root': {
                        border: `1px solid ${palette.secondary.dark}`,
                        boxShadow: 'none',
                        textAlign: 'left',
                    },
                }}
                id='menu-appbar'
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                <Box px={1}>
                    <Typography fontSize={'16px'} color={palette.secondary.contrastText}>
                        {user.firstName} {user.lastName}
                    </Typography>
                    <Typography fontSize={'14px'} color={palette.secondary.dark}>
                        {t(`userRoles.${UserRoles[user.role!]}`)}
                    </Typography>
                    <Typography color={palette.secondary.dark} fontSize={'14px'}>
                        {user.email}
                    </Typography>
                    <Button
                        startIcon={<LogoutIcon />}
                        onClick={() => setModalOpen(true)}
                        sx={{
                            mt: '15px',
                            width: '100%',
                        }}
                    >
                        {t('common.signOutButtonText')}
                    </Button>
                </Box>
            </CustomMenu>
        </Box>
    );
}
