import { errorHandlerService } from './services/errorHandlerServices';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Box, Typography, useTheme } from '@mui/material';
import { Button } from './components/styled/Button';
import { useNavigate } from 'react-router-dom';

function FallbackComponent() {
    const { t } = useTranslation();
    const { palette } = useTheme();
    const navigate = useNavigate();

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                pb: 5,
            }}
        >
            <Typography
                sx={{
                    fontSize: '22px',
                    fontWeight: '600',
                    color: palette.error.main,
                }}
            >
                {t('common.error')}
            </Typography>
            <Typography
                sx={{
                    padding: '10px 0',
                    fontSize: '16px',
                    textAlign: 'justify',
                }}
            >
                {t('errorMessages.unhandledErrorMessage')}
            </Typography>
            <Button onClick={() => navigate('/statistics')} sx={{ mt: 2 }}>
                {t('common.goBack')}
            </Button>
        </Box>
    );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function UnhandledErrorBoundary(props: any) {
    return (
        <ErrorBoundary
            FallbackComponent={FallbackComponent}
            onError={(error) => {
                errorHandlerService.logError(error);
            }}
        >
            {props.children}
        </ErrorBoundary>
    );
}
