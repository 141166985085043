import { SvgIconProps } from '@mui/material/SvgIcon';

export function NorwayFlagIcon({ props }: { props?: SvgIconProps }) {
    return (
        <svg
            width='24'
            height='24'
            viewBox='0 0 50 50'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <g clipPath='url(#clip0_5507_10566)'>
                <path
                    d='M49.1378 41.3793H0.862108C0.386034 41.3793 0 40.9933 0 40.5172V9.48277C0 9.0067 0.386034 8.62067 0.862108 8.62067H49.1379C49.614 8.62067 50 9.0067 50 9.48277V40.5172C49.9999 40.9934 49.6139 41.3793 49.1378 41.3793Z'
                    fill='#FF4B55'
                />
                <path
                    d='M49.9999 20.6897H22.4137V8.62079H13.793V20.6897H0V29.3104H13.793V41.3793H22.4137V29.3104H49.9999V20.6897Z'
                    fill='#F5F5F5'
                />
                <path
                    d='M49.9999 22.4138H20.6896V8.62079H15.5173V22.4138H0V27.5863H15.5173V41.3793H20.6896V27.5863H49.9999V22.4138Z'
                    fill='#41479B'
                />
            </g>
            <defs>
                <clipPath id='clip0_5507_10566'>
                    <rect width='50' height='50' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
}
