import { FillSvgIconProps } from './SvgIconProps';
import theme from '../../theme/theme';

export function EmptyStateSearchIcon({
    fill = theme.palette.primary.light,
    stroke = theme.palette.brandHovedfarge,
    ...props
}: FillSvgIconProps) {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='122'
            height='158'
            viewBox='0 0 122 158'
            fill='none'
            {...props}
        >
            <path
                d='M26.1313 10.4879C11.8846 10.4879 0.33545 22.0378 0.335449 36.284L0.335448 117.388C0.335448 131.634 11.8846 143.184 26.1313 143.184C27.7712 143.184 29.3141 144.029 30.1996 145.41C34.7858 152.558 42.8033 157.295 51.9272 157.295C66.1739 157.295 77.7231 145.745 77.7231 131.499C77.7231 130.312 79.0735 129.596 80.1157 130.165C83.7816 132.166 87.9867 133.304 92.4574 133.304C106.704 133.304 118.253 121.754 118.253 107.508L118.253 26.4043C118.253 12.1581 106.704 0.608156 92.4574 0.608155C78.2107 0.608155 66.6615 12.1581 66.6615 26.4043L66.6615 40.1761C66.6615 44.5226 61.65 47.3621 57.4017 46.4437C54.4984 45.8161 51.9272 43.4535 51.9272 40.4832L51.9272 36.284C51.9272 22.0378 40.378 10.4879 26.1313 10.4879Z'
                fill={fill}
            />
            <path
                d='M26.9453 40.4331C26.9453 38.224 28.7362 36.4331 30.9453 36.4331H79.5327C80.6012 36.4331 81.6253 36.8606 82.3766 37.6203L90.4875 45.8212C91.2282 46.5701 91.6436 47.5808 91.6436 48.634V111.603C91.6436 113.812 89.8527 115.603 87.6436 115.603H30.9453C28.7362 115.603 26.9453 113.812 26.9453 111.603V40.4331Z'
                stroke={stroke}
                strokeWidth='2'
                strokeLinecap='round'
                strokeDasharray='8 8'
            />
            <path
                d='M24.915 30.1125L24.915 25.1115'
                stroke={stroke}
                strokeWidth='2'
                strokeLinecap='round'
            />
            <path
                d='M15.0674 34.9595H20.0685'
                stroke={stroke}
                strokeWidth='2'
                strokeLinecap='round'
            />
            <path
                d='M16.5322 26.5764L20.0685 30.1127'
                stroke={stroke}
                strokeWidth='2'
                strokeLinecap='round'
            />
            <path
                d='M96.6512 119.208L97.0036 118.894L97.3373 119.228L100.871 122.762L101.148 123.038L100.946 123.373C99.8618 125.176 100.162 127.642 101.624 129L102.004 129.353L101.988 129.369L112.775 140.155C114.568 141.949 117.493 141.952 119.295 140.153L119.424 140.024C121.091 138.22 121.049 135.385 119.297 133.634L108.157 122.493C106.649 120.985 104.308 120.727 102.517 121.807L102.182 122.009L101.905 121.732L98.3691 118.196L98.0354 117.862L98.3492 117.51C105.514 109.464 105.2 97.161 97.5252 89.4864C89.5453 81.5065 76.6077 81.5065 68.6278 89.4864C60.6479 97.4663 60.6479 110.404 68.6278 118.384C76.3018 126.058 88.6059 126.373 96.6512 119.208Z'
                fill={stroke}
                stroke={stroke}
            />
            <circle cx='83.0818' cy='103.94' r='17.6277' fill='#E3F8E6' />
        </svg>
    );
}
