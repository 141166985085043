import {
    Configuration,
    PublicClientApplication,
    AuthenticationResult,
    EventType,
} from '@azure/msal-browser';
import { configurationService } from './appConfigService';

class AuthenticationConfigService {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private _loginRequest: any;
    private _msalConfiguration?: Configuration;
    private _msalInstance?: PublicClientApplication;

    private get msalConfiguration(): Configuration {
        if (!this._msalConfiguration) {
            this._msalConfiguration = {
                auth: {
                    clientId: configurationService.configuration.azureClientId,
                    authority: `https://login.microsoftonline.com/${configurationService.configuration.azureTenantId}`,
                    redirectUri: configurationService.configuration.azureRedirectUri,
                },

                cache: {
                    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
                    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
                },
            };
        }

        return this._msalConfiguration;
    }

    get loginRequest() {
        if (!this._loginRequest) {
            this._loginRequest = {
                scopes: [configurationService.configuration.loginScope],
            };
        }

        return this._loginRequest;
    }

    get msalInstance(): PublicClientApplication {
        if (!this._msalInstance) {
            this._msalInstance = new PublicClientApplication(this.msalConfiguration);
        }

        return this._msalInstance;
    }

    initialize(): void {
        const accounts = this.msalInstance.getAllAccounts();
        if (accounts.length > 0) {
            this.msalInstance.setActiveAccount(accounts[0]);
        }

        this.msalInstance.addEventCallback((event) => {
            if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
                const payload = event.payload as AuthenticationResult;
                const account = payload.account;
                this.msalInstance.setActiveAccount(account);
            }
        });
    }
}

export const authenticationConfigService = new AuthenticationConfigService();
