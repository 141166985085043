import { SortingEnum } from '../../enums/sortingEnum';

export const DefaultValueConstants = {
    InternalReminderTime: '12:00:00',
    CollectionSendingTime: '21:00:00',
    SoftReminderSendingTime: '12:00:00',
    DefaultPastDueDatePeriod: 14,
    DaysBeforeCollectionDate: 0,
    PageSizeOptions: [20, 50, 100],
    DefaultPage: 1,
    DefaultPageSize: 50,
    DefaultCurrency: 'NOK',
    DefaultSortingByName: SortingEnum.Asc,
    DefaultSortingByRiskLevel: SortingEnum.Desc,
    NoDataPlaceholder: '-',
    LoaderDelayInMs: 300,
    DefaultDaysAfterLastSoftReminder: 3,
    DefaultSoftReminderPrice: 4,
    DefaultExcludedFromAutoSoftReminderDebtorCount: 0,
};
