import { AuthenticationResult, InteractionRequiredAuthError } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { HomePage } from '../pages/HomePage';
import { errorHandlerService } from '../services/errorHandlerServices';
import { useCurrentUserService } from '../components/hooks/useCurrentUserService';
import { useLocation } from 'react-router';
import { authenticationService } from './authenticationService';
import { CircularLoader } from '../components/loaders/CircularLoader';
import { Button } from '../components/styled/Button';
import { useTranslation } from 'react-i18next';
import { Box, Typography, useTheme } from '@mui/material';

export function AuthProvider({ children }: { children: React.ReactNode }) {
    const { tryRefetchCurrentUser } = useCurrentUserService();
    const { inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [accessToken, setAccessToken] = useState<string | null>(null);
    const activeAccount = authenticationService.getActiveAccount();
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const { palette } = useTheme();

    useEffect(() => {
        const requestAccessToken = async () => {
            const account =
                authenticationService.getActiveAccount() ||
                authenticationService.getDefaultAccount();

            authenticationService
                .getAccessTokenByAccount(account)
                .then((response: AuthenticationResult | null) => {
                    setAccessToken(response ? response.accessToken : null);
                    const isUnauthorized = pathname === '/unauthorized';

                    if (!isUnauthorized) tryRefetchCurrentUser();
                })
                .catch((e) => {
                    if (e instanceof InteractionRequiredAuthError) {
                        authenticationService
                            .initiateTokenPopup(account)
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            .then((response: AuthenticationResult | null) => {
                                setAccessToken(response ? response.accessToken : null);
                                tryRefetchCurrentUser();
                            });
                    } else {
                        errorHandlerService.logError(e);
                    }
                });
        };

        if (isAuthenticated && accessToken === null) {
            requestAccessToken();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);

    return (
        <>
            {!isAuthenticated && !activeAccount ? (
                <HomePage
                    handleLogin={() => {
                        authenticationService.login();
                    }}
                />
            ) : accessToken ? (
                children
            ) : inProgress ? (
                <CircularLoader />
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100vh',
                        pb: 5,
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '22px',
                            fontWeight: '600',
                            color: palette.error.main,
                        }}
                    >
                        {t('common.error')}
                    </Typography>
                    <Typography
                        sx={{
                            padding: '10px 0',
                            fontSize: '16px',
                            textAlign: 'justify',
                        }}
                    >
                        {t('errorMessages.userAuthentication')}
                    </Typography>
                    <Button onClick={() => authenticationService.logout()} sx={{ mt: 2 }}>
                        {t('common.signOutButtonText')}
                    </Button>
                </Box>
            )}
        </>
    );
}
