import ReactDOM from 'react-dom';
import { useRecoilValue } from 'recoil';
import { globalLoadingState, isGlobalLoadingDisabled } from '../../store/recoilStore';
import { DebouncedComponent } from '../debounced/DebouncedComponent';
import { CircularLoader } from './CircularLoader';
import { DefaultValueConstants } from '../../types/constants/DefaultValueConstants';

export function DebouncedGlobalLoader({
    delayInMs = DefaultValueConstants.LoaderDelayInMs,
}: {
    delayInMs?: number;
}) {
    const globalLoading = useRecoilValue(globalLoadingState);
    const hideLoader = useRecoilValue(isGlobalLoadingDisabled);

    if (!globalLoading || hideLoader) {
        return null;
    }

    return (
        <>
            <DebouncedComponent showChild={globalLoading} delayInMs={delayInMs}>
                {ReactDOM.createPortal(<CircularLoader />, document.body)}
            </DebouncedComponent>
        </>
    );
}
