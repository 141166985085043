import { atom } from 'recoil';
import { ActionResolver } from '../types/ActionResolver';
import { AccessibleCreditorDto } from '../types/CreditorTypes';
import { DebtorInfo } from '../types/DebtorTypes';
import { DebtorForGrid, SelectedDebtors } from '../types/InvoicesTypes';
import { User } from '../types/UserTypes';

export const selectionState = atom<SelectedDebtors>({
    key: 'selectionState',
    default: new Map(),
});

export const noEmailDebtorInfosState = atom<Pick<DebtorForGrid, 'id' | 'customerName'>[]>({
    key: 'noEmailDebtorInfosState',
    default: new Array<Pick<DebtorForGrid, 'id' | 'customerName'>>(),
});

export const authenticatedUser = atom<User | undefined>({
    key: 'authenticatedUser',
    default: undefined,
});

export const timelineActivityRefetchMarker = atom<boolean>({
    key: 'timelineActivityRefetchMarker',
    default: false,
});

export const selectedCreditorState = atom<AccessibleCreditorDto | null>({
    key: 'selectedCreditorState',
    default: null,
});

export const userIdsToAssign = atom<Set<number>>({
    key: 'userIdsToAssign',
    default: new Set(),
});

export const userIdsToCreditorResponsibleAction = atom<Set<number>>({
    key: 'userIdsToCreditorResponsibleAction',
    default: new Set(),
});

export const globalLoadingState = atom<boolean>({
    key: 'globalLoadingState',
    default: true,
});

export const isGlobalLoadingDisabled = atom<boolean>({
    key: 'isGlobalLoadingDisabled',
    default: false,
});

export const debtorInfosToAddResponsiblePeople = atom<Map<number, DebtorInfo>>({
    key: 'debtorInfosToAddResponsiblePeople',
    default: new Map(),
});

export const userIdsToAddDebtorsResponsiblePeople = atom<Set<number>>({
    key: 'userIdsToAddDebtorsResponsiblePeople',
    default: new Set(),
});

export const accessibleCreditorsState = atom<AccessibleCreditorDto[] | undefined>({
    key: 'accessibleCreditorsState',
    default: undefined,
});

export const userIdsToRemoveDebtorsResponsiblePeople = atom<Set<number>>({
    key: 'userIdsToRemoveDebtorsResponsiblePeople',
    default: new Set(),
});

export const needUpdateDebtorsTable = atom<boolean>({
    key: 'needUpdateDebtorsTable',
    default: false,
});

export const needUpdateDebtorRecipientsTable = atom<boolean>({
    key: 'needUpdateDebtorRecipientsTable',
    default: false,
});

export const userIdsToSendReminder = atom<Set<number>>({
    key: 'userIdsToSendReminder',
    default: new Set(),
});

export const needUpdateDebtorDetailsTable = atom<boolean>({
    key: 'needUpdateDebtorDetailsTable',
    default: false,
});

export const refreshRiskIndicatorState = atom<Map<number, ActionResolver>>({
    key: 'refreshRiskIndicatorState',
    default: new Map<number, ActionResolver>(),
});
