const formatOptions = {
    decimal: { minimumFractionDigits: 2, maximumFractionDigits: 2 },
    integer: { maximumFractionDigits: 0 },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function formatInteger(value: any): string {
    return formatNumber(value, formatOptions.integer);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function formatDecimal(value: any): string {
    return formatNumber(value, formatOptions.decimal);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function formatNumber(value: any, formatOptions?: any): string {
    // todo: try to figure out why userLocale doesn't format numbers according to Windows settings
    // const userLocale =
    //     navigator.languages && navigator.languages.length
    //         ? navigator.languages[0]
    //         : navigator.language;

    if (typeof value === 'number') return value.toLocaleString('nb-NO', formatOptions);
    else return value;
}
