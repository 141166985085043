/* eslint-disable react/prop-types */
import { ReactElement, Suspense, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { globalLoadingState } from '../../store/recoilStore';
import { DefaultValueConstants } from '../../types/constants/DefaultValueConstants';
import { CircularLoader } from './CircularLoader';

interface SuspenseWrapperProps {
    component: ReactElement;
}

interface LazyLoaderProps {
    delayInMs?: number;
}

const LazyLoader: React.FC<LazyLoaderProps> = ({
    delayInMs = DefaultValueConstants.LoaderDelayInMs,
}) => {
    const loading = useRecoilValue(globalLoadingState);
    const [show, setShow] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setShow(true);
        }, delayInMs);

        return () => {
            clearTimeout(timeout);
        };
    }, [delayInMs]);

    return <>{show && !loading && CircularLoader}</>;
};

export const ModuleSuspenseWrapper: React.FC<SuspenseWrapperProps> = ({ component }) => {
    return <Suspense fallback={<LazyLoader />}>{component}</Suspense>;
};
