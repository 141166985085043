import { Box } from '@mui/material';
import React from 'react';

interface FullscreenContainerProps {
    backgroundImage: string;
    children?: React.ReactNode;
    sx?: React.CSSProperties;
}

export const FullscreenBox: React.FC<FullscreenContainerProps> = ({
    backgroundImage,
    children,
    sx,
}) => {
    return (
        <Box
            sx={{
                background: `url(${backgroundImage}) no-repeat center / cover`,
                minHeight: '100vh',
                ...sx,
            }}
        >
            {children}
        </Box>
    );
};
