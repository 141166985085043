import { FormControl, MenuItem, Select, Stack } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EnglishFlagIcon } from '../Icons/EnglishFlagIcon';
import { NorwayFlagIcon } from '../Icons/NorwayFlagIcon';

export function LocalizationSwitcher() {
    const { i18n } = useTranslation();
    const [localization, setLocalization] = useState(i18n.resolvedLanguage);
    return (
        <FormControl>
            <Select
                sx={{
                    ml: '22px',
                    height: '32px',
                    '.MuiSelect-icon': {
                        fontSize: '18px',
                    },
                }}
                value={localization === 'en' ? 'en' : 'no'}
                onChange={(e) => {
                    setLocalization(e.target.value as string);
                    i18n.changeLanguage(e.target.value as string);
                }}
            >
                <MenuItem value='en'>
                    <Stack direction={'row'} gap={0.5}>
                        <EnglishFlagIcon />
                    </Stack>
                </MenuItem>
                <MenuItem value='no'>
                    <Stack direction={'row'} gap={0.5}>
                        <NorwayFlagIcon />
                    </Stack>
                </MenuItem>
            </Select>
        </FormControl>
    );
}
