import { ThemeProvider } from '@mui/material';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/nb';
import utcPlugin from 'dayjs/plugin/utc';
import { SnackbarProvider } from 'notistack';
import { lazy } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes } from 'react-router-dom';
import { UnhandledErrorBoundary } from './UnhandledErrorBoundary';
import { AuthProvider } from './auth/Auth';
import { ErrorSnackbar, SuccessfulSnackbar, WarningSnackbar } from './components/CustomSnackbar';
import { EmptyState } from './components/EmptyState';
import { Layout } from './components/Layout';
import { ModuleSuspenseWrapper } from './components/loaders/ModuleSuspenseWrapper';
import theme from './theme/theme';
dayjs.extend(utcPlugin);

declare module 'notistack' {
    interface VariantOverrides {
        successfulSnackbar: true;
        errorSnackbar: true;
        warningSnackbar: true;
    }
}

const Forbidden = lazy(() => import('./pages/Forbidden'));
const Invoices = lazy(() => import('./pages/Invoices'));
const CollectionHistory = lazy(() => import('./pages/CollectionHistory'));
const Debtors = lazy(() => import('./pages/Debtors'));
const DetailsSection = lazy(() => import('./views/debtors/detailsSection/DebtorDetailsSection'));
const Statistics = lazy(() => import('./pages/Statistics'));
const Admin = lazy(() => import('./pages/Admin'));
const UnauthorizedPage = lazy(() => import('./pages/UnauthorizedPage'));
const SoftReminderEmailActionsPage = lazy(() => import('./pages/SoftReminderEmailActionsPage'));
const InternalReminderEmailActionsPage = lazy(
    () => import('./pages/InternalReminderEmailActionsPage'),
);

export default function App() {
    const { i18n } = useTranslation();
    if (i18n.resolvedLanguage === 'en') {
        dayjs.locale('en');
    } else if (i18n.resolvedLanguage === 'no') {
        dayjs.locale('nb');
    }

    return (
        <SnackbarProvider
            maxSnack={3}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            Components={{
                successfulSnackbar: SuccessfulSnackbar,
                errorSnackbar: ErrorSnackbar,
                warningSnackbar: WarningSnackbar,
            }}
        >
            <UnhandledErrorBoundary>
                <ThemeProvider theme={theme}>
                    <Routes>
                        <Route path='/public/*' element={<PublicRoutes />} />
                        <Route path='/*' element={<ProtectedRoutes />} />
                    </Routes>
                </ThemeProvider>
            </UnhandledErrorBoundary>
        </SnackbarProvider>
    );
}

function PublicRoutes() {
    return (
        <Routes>
            <Route
                path='/soft-reminders/emails/:emailToken/:action'
                element={<ModuleSuspenseWrapper component={<SoftReminderEmailActionsPage />} />}
            />
            <Route
                path='/internal-reminders/emails/:emailToken/actions'
                element={<ModuleSuspenseWrapper component={<InternalReminderEmailActionsPage />} />}
            />
        </Routes>
    );
}

function ProtectedRoutes() {
    const { t } = useTranslation();

    return (
        <AuthProvider>
            <Routes>
                <Route element={<Layout />}>
                    <Route path='/' element={<Navigate to='/statistics' replace />} />
                    <Route
                        path='/forbidden'
                        element={<ModuleSuspenseWrapper component={<Forbidden />} />}
                    />
                    <Route
                        path='/invoices'
                        element={<ModuleSuspenseWrapper component={<Invoices />} />}
                    />
                    <Route
                        path='/collection-history'
                        element={<ModuleSuspenseWrapper component={<CollectionHistory />} />}
                    />
                    <Route
                        path='/customers'
                        element={<ModuleSuspenseWrapper component={<Debtors />} />}
                    >
                        <Route
                            index
                            element={
                                <EmptyState message={t('debtorsSection.noSelectedDebtorMessage')} />
                            }
                        />
                        <Route
                            path=':debtorId/:activeTab'
                            element={<ModuleSuspenseWrapper component={<DetailsSection />} />}
                        />
                        <Route
                            path=':debtorId'
                            element={<ModuleSuspenseWrapper component={<DetailsSection />} />}
                        />
                    </Route>
                    <Route
                        path='/statistics'
                        element={<ModuleSuspenseWrapper component={<Statistics />} />}
                    />
                    <Route
                        path='/admin'
                        element={<ModuleSuspenseWrapper component={<Admin />} />}
                    />
                </Route>
                <Route path='/unauthorized' element={<UnauthorizedPage />} />
            </Routes>
        </AuthProvider>
    );
}
